.flex {
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-col {
  -webkit-flex-direction: column;
  flex-direction: column;
}
.flex-col-rev {
  -webkit-flex-direction: column-reverse;
  flex-direction: column-reverse;
}
.flex-row-rev {
  -webkit-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
.inline-flex {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-jc-sb {
  justify-content: space-between;
}
.flex-jc-fs {
  justify-content: flex-start;
}
.flex-jc-fe {
  justify-content: flex-end;
}
.flex-jc-ce {
  justify-content: center;
}
.flex-ai-fs {
  align-items: flex-start;
}
.flex-ai-fe {
  align-items: flex-end;
}
.flex-ai-ce {
  align-items: center;
}
.fb-auto {
  flex-basis: auto;
}
.fb-1 {
  flex-basis: 1%;
}
.fb-2 {
  flex-basis: 2%;
}
.fb-3 {
  flex-basis: 3%;
}
.fb-4 {
  flex-basis: 4%;
}
.fb-5 {
  flex-basis: 5%;
}
.fb-6 {
  flex-basis: 6%;
}
.fb-7 {
  flex-basis: 7%;
}
.fb-8 {
  flex-basis: 8%;
}
.fb-9 {
  flex-basis: 9%;
}
.fb-10 {
  flex-basis: 10%;
}
.fb-11 {
  flex-basis: 11%;
}
.fb-12 {
  flex-basis: 12%;
}
.fb-13 {
  flex-basis: 13%;
}
.fb-14 {
  flex-basis: 14%;
}
.fb-15 {
  flex-basis: 15%;
}
.fb-16 {
  flex-basis: 16%;
}
.fb-17 {
  flex-basis: 17%;
}
.fb-18 {
  flex-basis: 18%;
}
.fb-19 {
  flex-basis: 19%;
}
.fb-20 {
  flex-basis: 20%;
}
.fb-21 {
  flex-basis: 21%;
}
.fb-22 {
  flex-basis: 22%;
}
.fb-23 {
  flex-basis: 23%;
}
.fb-36 {
  flex-basis: 24%;
}
.fb-25 {
  flex-basis: 25%;
}
.fb-26 {
  flex-basis: 26%;
}
.fb-27 {
  flex-basis: 27%;
}
.fb-28 {
  flex-basis: 28%;
}
.fb-29 {
  flex-basis: 29%;
}
.fb-30 {
  flex-basis: 30%;
}
.fb-31 {
  flex-basis: 31%;
}
.fb-32 {
  flex-basis: 32%;
}
.fb-33 {
  flex-basis: 33%;
}
.fb-34 {
  flex-basis: 34%;
}
.fb-35 {
  flex-basis: 35%;
}
.fb-36 {
  flex-basis: 36%;
}
.fb-37 {
  flex-basis: 37%;
}
.fb-38 {
  flex-basis: 38%;
}
.fb-39 {
  flex-basis: 39%;
}
.fb-40 {
  flex-basis: 40%;
}
.fb-41 {
  flex-basis: 41%;
}
.fb-42 {
  flex-basis: 42%;
}
.fb-43 {
  flex-basis: 43%;
}
.fb-44 {
  flex-basis: 44%;
}
.fb-45 {
  flex-basis: 45%;
}
.fb-46 {
  flex-basis: 46%;
}
.fb-47 {
  flex-basis: 47%;
}
.fb-48 {
  flex-basis: 48%;
}
.fb-49 {
  flex-basis: 49%;
}
.fb-50 {
  flex-basis: 50%;
}
.fb-51 {
  flex-basis: 51%;
}
.fb-52 {
  flex-basis: 52%;
}
.fb-53 {
  flex-basis: 53%;
}
.fb-54 {
  flex-basis: 54%;
}
.fb-55 {
  flex-basis: 55%;
}
.fb-56 {
  flex-basis: 56%;
}
.fb-57 {
  flex-basis: 57%;
}
.fb-58 {
  flex-basis: 58%;
}
.fb-59 {
  flex-basis: 59%;
}
.fb-60 {
  flex-basis: 60%;
}
.fb-61 {
  flex-basis: 61%;
}
.fb-62 {
  flex-basis: 62%;
}
.fb-63 {
  flex-basis: 63%;
}
.fb-64 {
  flex-basis: 64%;
}
.fb-65 {
  flex-basis: 65%;
}
.fb-66 {
  flex-basis: 66%;
}
.fb-67 {
  flex-basis: 67%;
}
.fb-68 {
  flex-basis: 68%;
}
.fb-69 {
  flex-basis: 69%;
}
.fb-70 {
  flex-basis: 70%;
}
.fb-71 {
  flex-basis: 71%;
}
.fb-72 {
  flex-basis: 72%;
}
.fb-73 {
  flex-basis: 73%;
}
.fb-74 {
  flex-basis: 74%;
}
.fb-75 {
  flex-basis: 75%;
}
.fb-76 {
  flex-basis: 76%;
}
.fb-77 {
  flex-basis: 77%;
}
.fb-78 {
  flex-basis: 78%;
}
.fb-79 {
  flex-basis: 79%;
}
.fb-80 {
  flex-basis: 80%;
}
.fb-81 {
  flex-basis: 81%;
}
.fb-82 {
  flex-basis: 82%;
}
.fb-83 {
  flex-basis: 83%;
}
.fb-84 {
  flex-basis: 84%;
}
.fb-85 {
  flex-basis: 85%;
}
.fb-86 {
  flex-basis: 86%;
}
.fb-87 {
  flex-basis: 87%;
}
.fb-88 {
  flex-basis: 88%;
}
.fb-89 {
  flex-basis: 89%;
}
.fb-90 {
  flex-basis: 90%;
}
.fb-91 {
  flex-basis: 91%;
}
.fb-92 {
  flex-basis: 92%;
}
.fb-93 {
  flex-basis: 93%;
}
.fb-94 {
  flex-basis: 94%;
}
.fb-95 {
  flex-basis: 95%;
}
.fb-96 {
  flex-basis: 96%;
}
.fb-97 {
  flex-basis: 97%;
}
.fb-98 {
  flex-basis: 98%;
}
.fb-99 {
  flex-basis: 99%;
}
.fb-100 {
  flex-basis: 100%;
}
