.container {
}
.spinnerContainer {
  z-index: -99;
}
.spinner {
  transition: all var(--speed-3) var(--ease);
}
.preloader {
  z-index: 999;
}
.cursor {
  position: fixed;
  z-index: 999;
  mix-blend-mode: difference;
}
.scroller {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 499;
}

.root {
}
/* .footer {
  position: fixed;
  bottom: 0;
  z-index: 200;
} */

/* .topBorder,
.bottomBorder {
  position: fixed;
  display: block;
  height: calc(1 * var(--appbar-height));
  width: 100vw;
  transition: transform var(--speed-2) var(--ease);
  
}

.topBorder {
  top: var(--appbar-height);
  transform-origin: center top;
  background: linear-gradient(180deg, var(--bg), var(--bg-transp));
}

.bottomBorder {
  bottom: var(--appbar-height);
  transform-origin: center bottom;
  background: linear-gradient(0deg, var(--bg), var(--bg-transp));
  z-index: -1;
} */

.samarthJajodia {
  visibility: hidden;
  width: 100vw;
  padding: var(--spacing);
  padding-bottom: 0;
}
.svgSJ {
  height: 100%;
  width: calc(100vw - (2 * var(--spacing)));
}
