@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&display=swap");

:root {
  font-family: "DM Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px; /*sets the value for 1rem (--base-font-size)*/
  font-weight: 400;
  letter-spacing: 0.005rem;
  line-height: 1;
  font-variant-numeric: ordinal;
  font-feature-settings: "ss03" on, "salt" on;
  --base-font-size: 1rem;
  --scale: 1.333; /* Augmented Fourth */
  --fs-caption: calc(var(--base-font-size) / var(--scale));
  --fs-body: var(--base-font-size); /* YES - 16.00(base) */
  --fs-heading-4: calc(var(--base-font-size) * var(--scale)); /* YES - 22.62 */
  --fs-heading-3: calc(var(--fs-heading-4) * var(--scale)); /* NO - 31.99 */
  --fs-heading-2: calc(var(--fs-heading-3) * var(--scale)); /* YES - 45.23 */
  --fs-heading-1: calc(var(--fs-heading-2) * var(--scale)); /* NO - 65.96 */
  --fs-heading-0: calc(var(--fs-heading-1) * var(--scale)); /* NO -  90.44 */
  --fs-heading-x: calc(var(--fs-heading-0) * var(--scale)); /*  NO - 127.88 */
  --fs-heading-xx: calc(
    var(--fs-heading-x) * var(--scale)
  ); /* YES - 180.83px */
}

@media screen and (max-width: 600px) {
  :root {
    /* --scale: 1.333; */
    /* font-size: 16px; */
  }
}

.font-heading-1 {
  font-weight: 500;
  /* text-transform: lowercase; */
  font-size: var(--fs-heading-1);
}
.font-heading-2 {
  font-size: var(--fs-heading-2);
}
.font-heading-3 {
  font-size: var(--fs-heading-3);
  line-height: 1.05;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.font-heading-4 {
  font-size: var(--fs-heading-4);
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0.04rem;
}

.font-heading-small {
  font-size: calc(var(--fs-caption) + 2px);
  font-weight: 600;
  line-height: 1.1;
  letter-spacing: 0.015rem;
}
.font-body-heading {
  font-weight: 600;
  letter-spacing: 0.035rem;
}
.font-body-small {
  font-size: calc(var(--fs-caption) + 2px);
  line-height: 1.1;
  letter-spacing: 0.01rem;
}
.font-body-ital {
  font-style: italic;
}

.font-body-serif {
  font-weight: 500;

  line-height: 1.25;
  letter-spacing: -0.01rem;
}

.font-quote {
  /* font-weight: 600; */
  line-height: 1.25;
  letter-spacing: -0.005rem;
}

.font-caption {
  font-size: calc(var(--fs-caption));
  line-height: 1.15;
  letter-spacing: 0.01rem;
  text-transform: uppercase;
}
.font-caption-bold {
  font-size: var(--fs-caption);
  line-height: 1.15;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.03rem;
}

.tt-uc {
  text-transform: uppercase;
}

.tt-lc {
  text-transform: lowercase;
}

.t-i {
  text-indent: calc(var(--spacing) * 2);
}

/* navigation links... header, control bar */

.link {
  opacity: 1;
  transition: opacity var(--speed-0) var(--ease);
}
.linkDisabled {
  opacity: var(--opac-lo);
}
.link:hover,
.linkActive,
.linkActive:hover {
  opacity: var(--opac-mid);
}
.va-s {
  vertical-align: super;
}
